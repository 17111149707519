import { DateTime } from 'luxon';
import Methods from '../Methods';
import ITask from '../Models/ITask';
import IMutation from '../Mutations/IMutation';
import Transport, { ITransport } from '../Transports';
import CollaboratorRole from '../Values/CollaboratorRole';
import IAttachment from '../Values/IAttachment';
import TaskType from '../Values/TaskType';
import ApprovementStatus from '../Values/ApprovementStatus';
import { IOrder } from '../Values/IOrder';
import TaskExportSchema from '../Values/TaskExportSchema';

type TaskIncludes =
    | 'stories'
    | 'approvements'
    | 'collaborators'
    | 'comments-count'
    | 'attachments-count'
    | 'save-points-count';

export interface QueryTaskRequest extends IOrder {
    page?: number;
    perPage?: number;
    search?: string | undefined;
    whereId?: Array<string | number>;
    whereType?: Array<string | number>;
    whereTitle?: Array<string | number>;
    whereTitleNot?: Array<string>;
    whereStatus?: Array<string | number>;
    whereTaskId?: Array<string | number>;
    whereColumnId?: Array<string | number>;
    whereBoardId?: Array<string | number>;
    whereProjectId?: Array<string | number>;
    includes?: TaskIncludes[];
    whereAuthor?: Array<string>;
    whereApprover?: Array<string>;
    whereAssignee?: Array<string>;
    whereContributor?: Array<string>;
    whereCollaborator?: Array<string>;
    whereAuthorOrAssignee?: Array<string>;
    whereApprovementRequired?: boolean;
    whereCreatedBetween?: string[];
    whereDeadlineBetween?: string[];
    withArchived?: boolean;
}

export interface ExportTaskRequest extends QueryTaskRequest {
    schema?: TaskExportSchema;
    language?: string;
    withColumns?: string[];
}

export interface CreateCommentRequest {
    text: string;
    attachments: Array<IAttachment>;
}

class TaskService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    // Checks whether the task matches the specified filters
    public match(task: ITask, userId: string, filters: QueryTaskRequest): boolean {
        if (filters.whereId?.length && task.id && !filters.whereId.includes(task.id)) {
            return false;
        }

        if (filters.whereType?.length && task.type && !filters.whereType.includes(task.type)) {
            return false;
        }

        if (filters.whereTitle?.length && task.title && !filters.whereTitle.includes(task.title)) {
            return false;
        }

        if (filters.whereTitleNot?.length && task.title && filters.whereTitleNot.includes(task.title)) {
            return false;
        }

        if (filters.whereStatus?.length && task.status && !filters.whereStatus.includes(task.status)) {
            return false;
        }

        if (filters.whereTaskId?.length && (!task.parentId || !filters.whereTaskId.includes(task.parentId))) {
            return false;
        }

        if (filters.whereColumnId?.length && (!task.columnId || !filters.whereColumnId.includes(task.columnId))) {
            return false;
        }

        if (filters.whereBoardId?.length && (!task.boardId || !filters.whereBoardId.includes(task.boardId))) {
            return false;
        }

        if (filters.whereProjectId?.length && (!task.projectId || !filters.whereProjectId.includes(task.projectId))) {
            return false;
        }

        if (filters.whereAuthor?.length && task.authorId && !filters.whereAuthor.includes(task.authorId)) {
            return false;
        }

        if (
            filters.whereApprover?.length &&
            task.collaborators &&
            !filters.whereApprover.some((approverId) =>
                task.approvements?.some((collaborator) => collaborator.approverId === approverId),
            )
        ) {
            return false;
        }

        if (
            filters.whereAssignee?.length &&
            task.collaborators &&
            !filters.whereAssignee.some((assigneeId) =>
                task.collaborators?.some(
                    (collaborator) =>
                        collaborator.role === CollaboratorRole.Assignee && assigneeId === collaborator.userId,
                ),
            )
        ) {
            return false;
        }

        if (
            filters.whereContributor?.length &&
            task.collaborators &&
            !filters.whereContributor.some((assigneeId) =>
                task.collaborators?.some(
                    (collaborator) =>
                        collaborator.role === CollaboratorRole.Contributor && assigneeId === collaborator.userId,
                ),
            )
        ) {
            return false;
        }

        if (
            filters.whereCollaborator?.length &&
            task.collaborators &&
            !filters.whereCollaborator.some((assigneeId) =>
                task.collaborators?.some((collaborator) => assigneeId === collaborator.userId),
            )
        ) {
            return false;
        }

        if (
            filters.whereAuthorOrAssignee?.length &&
            (task.collaborators || task.authorId) &&
            !filters.whereAuthorOrAssignee.includes(task.authorId as string) &&
            !filters.whereAuthorOrAssignee.some((assigneeId) =>
                task.collaborators?.some(
                    (collaborator) => CollaboratorRole.Assignee && assigneeId === collaborator.userId,
                ),
            )
        ) {
            return false;
        }

        if (
            filters.whereApprovementRequired &&
            task.approvements &&
            !task.approvements?.some(
                (approvement) => approvement.approverId === userId && approvement.status === ApprovementStatus.Waiting,
            )
        ) {
            return false;
        }

        if (filters.search && task.title && task.title.indexOf(filters.search) === -1) {
            return false;
        }

        if (filters.whereCreatedBetween && task.createdAt && filters.whereCreatedBetween.length === 2) {
            const date = DateTime.fromISO(task.createdAt, { zone: 'UTC' });
            const left = DateTime.fromISO(filters.whereCreatedBetween[0], { zone: 'UTC' });
            const right = DateTime.fromISO(filters.whereCreatedBetween[1], { zone: 'UTC' });

            if (date && left && right && (date < left || date > right)) {
                return false;
            }
        }

        if (filters.whereDeadlineBetween && task.deadline && filters.whereDeadlineBetween.length === 2) {
            const date = DateTime.fromISO(task.deadline, { zone: 'UTC' });
            const left = DateTime.fromISO(filters.whereDeadlineBetween[0], { zone: 'UTC' });
            const right = DateTime.fromISO(filters.whereDeadlineBetween[1], { zone: 'UTC' });

            if (date && left && right && (date < left || date > right)) {
                return false;
            }
        }

        return true;
    }

    // Checks if there are any changes in the patch that may affect the result of the filtering.
    public matchPatch(patch: Partial<ITask>, userId: string, filters: QueryTaskRequest): boolean {
        if (
            filters.whereId?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'id') &&
            filters.whereId.includes(patch.id as number)
        ) {
            return true;
        }

        if (
            filters.whereType?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'type') &&
            filters.whereType.includes(patch.type as number)
        ) {
            return true;
        }

        if (
            filters.whereTitle?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'title') &&
            filters.whereTitle.includes(patch.title as string)
        ) {
            return true;
        }

        if (
            filters.whereTitleNot?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'title') &&
            !filters.whereTitleNot.includes(patch.title as string)
        ) {
            return true;
        }

        if (
            filters.whereStatus?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'status') &&
            filters.whereStatus.includes(patch.status as number)
        ) {
            return true;
        }

        if (
            filters.whereTaskId?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'parentId') &&
            filters.whereTaskId.includes(patch.parentId as number)
        ) {
            return true;
        }

        if (
            filters.whereColumnId?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'columnId') &&
            filters.whereColumnId.includes(patch.columnId as number)
        ) {
            return true;
        }

        if (
            filters.whereBoardId?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'boardId') &&
            filters.whereBoardId.includes(patch.boardId as number)
        ) {
            return true;
        }

        if (
            filters.whereProjectId?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'projectId') &&
            filters.whereProjectId.includes(patch.projectId as number)
        ) {
            return true;
        }

        if (
            filters.whereAuthor?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'authorId') &&
            !filters.whereAuthor.includes(patch.authorId as string)
        ) {
            return true;
        }

        if (
            filters.whereAssignee &&
            Object.prototype.hasOwnProperty.call(patch, 'collaborators') &&
            filters.whereAssignee.some((assigneeId) =>
                patch.collaborators?.some(
                    (collaborator) =>
                        collaborator.role === CollaboratorRole.Assignee && assigneeId === collaborator.userId,
                ),
            )
        ) {
            return true;
        }

        if (
            filters.whereApprover &&
            Object.prototype.hasOwnProperty.call(patch, 'approvements') &&
            filters.whereApprover.some((assigneeId) =>
                patch.approvements?.some((approvement) => approvement.approverId === assigneeId),
            )
        ) {
            return true;
        }

        if (
            filters.whereContributor?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'collaborators') &&
            filters.whereContributor.some((assigneeId) =>
                patch.collaborators?.some(
                    (collaborator) =>
                        collaborator.role === CollaboratorRole.Contributor && assigneeId === collaborator.userId,
                ),
            )
        ) {
            return true;
        }

        if (
            filters.whereCollaborator?.length &&
            Object.prototype.hasOwnProperty.call(patch, 'collaborators') &&
            filters.whereCollaborator.some((assigneeId) =>
                patch.collaborators?.some((collaborator) => assigneeId === collaborator.userId),
            )
        ) {
            return true;
        }

        if (
            filters.whereAuthorOrAssignee?.length &&
            (Object.prototype.hasOwnProperty.call(patch, 'collaborators') ||
                Object.prototype.hasOwnProperty.call(patch, 'authorId')) &&
            (filters.whereAuthorOrAssignee.includes(patch.authorId as string) ||
                filters.whereAuthorOrAssignee.some((assigneeId) =>
                    patch.collaborators?.some(
                        (collaborator) => CollaboratorRole.Assignee && assigneeId === collaborator.userId,
                    ),
                ))
        ) {
            return true;
        }

        if (
            filters.whereApprovementRequired &&
            Object.prototype.hasOwnProperty.call(patch, 'approvements') &&
            patch.approvements?.some(
                (approvement) => approvement.approverId === userId && approvement.status === ApprovementStatus.Waiting,
            )
        ) {
            return true;
        }

        if (
            filters.search &&
            Object.prototype.hasOwnProperty.call(patch, 'title') &&
            (patch.title as string).indexOf(filters.search) !== -1
        ) {
            return true;
        }

        if (
            filters.whereCreatedBetween &&
            Object.prototype.hasOwnProperty.call(patch, 'createdAt') &&
            filters.whereCreatedBetween.length === 2
        ) {
            const date = DateTime.fromISO(patch.createdAt as string, { zone: 'UTC' });
            const left = DateTime.fromISO(filters.whereCreatedBetween[0], { zone: 'UTC' });
            const right = DateTime.fromISO(filters.whereCreatedBetween[1], { zone: 'UTC' });

            if (date && left && right && left < date && date < right) {
                return true;
            }
        }

        if (
            filters.whereDeadlineBetween &&
            Object.prototype.hasOwnProperty.call(patch, 'deadline') &&
            filters.whereDeadlineBetween.length === 2
        ) {
            const date = DateTime.fromISO(patch.deadline as string, { zone: 'UTC' });
            const left = DateTime.fromISO(filters.whereDeadlineBetween[0], { zone: 'UTC' });
            const right = DateTime.fromISO(filters.whereDeadlineBetween[1], { zone: 'UTC' });

            if (date && left && right && left < date && date < right) {
                return true;
            }
        }

        return false;
    }

    public queryAsync(context: QueryTaskRequest): Promise<ITask[]> {
        return this._transport.invokeAsync<QueryTaskRequest, ITask[]>(Methods.Get, `/api/v1/tasks`, context);
    }

    public findAsync(taskId: number): Promise<ITask> {
        return this._transport.invokeAsync<undefined, ITask>(Methods.Get, `/api/v1/tasks/${taskId}`);
    }

    public createAsync(type: TaskType, parentId: number | null = null): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ type: TaskType; parentId: number | null }, IMutation[]>(
            Methods.Post,
            '/api/v1/tasks',
            {
                type,
                parentId,
            },
        );
    }

    public createCommentAsync(taskId: number, comment: CreateCommentRequest): Promise<IMutation[]> {
        return this._transport.invokeAsync<CreateCommentRequest, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/comments`,
            {
                text: comment.text,
                attachments: comment.attachments,
            },
        );
    }

    public updateCommentAsync(taskId: number, commentId: number, text: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ text: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/comments/${commentId}`,
            {
                text,
            },
        );
    }

    public deleteStoryAsync(taskId: number, storyId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(
            Methods.Delete,
            `/api/v1/tasks/${taskId}/stories/${storyId}`,
        );
    }

    public createAttachmentAsync(taskId: number, objectName: string, fileName: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ objectName: string; fileName: string }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/attachments`,
            {
                fileName,
                objectName,
            },
        );
    }

    public setTitleAsync(taskId: number, title: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ title: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/title`,
            {
                title,
            },
        );
    }

    public setParentAsync(taskId: number, parentId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ parentId: number }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/parent`,
            {
                parentId,
            },
        );
    }

    public setDeadlineAsync(taskId: number, deadline: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ deadline: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/deadline`,
            {
                deadline,
            },
        );
    }

    public setAuthorAsync(taskId: number, authorId: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ authorId: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/author`,
            {
                authorId,
            },
        );
    }

    public moveAsync(taskId: number, columnId: number, rank: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/move`, {
            rank,
            columnId,
        });
    }

    public setDescriptionAsync(taskId: number, description: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ description: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/description`,
            {
                description,
            },
        );
    }

    public addCollaboratorAsync(taskId: number, collaboratorId: string, role: CollaboratorRole): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ collaboratorId: string; role: CollaboratorRole }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/collaborators`,
            {
                collaboratorId,
                role,
            },
        );
    }

    public removeCollaboratorAsync(
        taskId: number,
        collaboratorId: string,
        role: CollaboratorRole,
    ): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ role: CollaboratorRole }, IMutation[]>(
            Methods.Delete,
            `/api/v1/tasks/${taskId}/collaborators/${collaboratorId}`,
            {
                role,
            },
        );
    }

    public replaceCollaboratorAsync(
        taskId: number,
        oldCollaboratorId: string,
        newCollaboratorId: string,
        role: CollaboratorRole,
    ): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ role: CollaboratorRole }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/collaborators/${oldCollaboratorId}/replace/${newCollaboratorId}`,
            {
                role,
            },
        );
    }

    public startApprovementProcessAsync(taskId: number, approverIds: string[]): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ approverIds: string[] }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/approvements`,
            {
                approverIds,
            },
        );
    }

    public completeAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/complete`);
    }

    public finishAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/finish`);
    }

    public toDraftAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/draft`);
    }

    public toInProgressAsync(taskId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/in-progress`,
            {
                comment,
            },
        );
    }

    public archiveAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/archive`);
    }

    public approveAsync(taskId: number, approvementId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/approvements/${approvementId}/approve`,
            {
                comment,
            },
        );
    }

    public disapproveAsync(taskId: number, approvementId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/approvements/${approvementId}/disapprove`,
            {
                comment,
            },
        );
    }

    public rollbackChangesAsync(taskId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/rollback-changes`,
            {
                comment,
            },
        );
    }

    public copyAsync(targetId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ targetId: number }, IMutation[]>(
            Methods.Post,
            '/api/v1/tasks/ctrl-c_plus_ctrl-v',
            {
                targetId,
            },
        );
    }
}

const service = new TaskService(Transport);

Object.freeze(service);

export default service;
